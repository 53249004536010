import ParticleMain from "../components/particles/Particle";

import "./contact.css";
import { Container, Row, Col } from "react-bootstrap";
import ContactForm from "../components/Form";

const Contact = () => {
  return (
    <div>
    <section className="contact" id="contact">
      <h2 className="animate__animated animate__zoomIn">Ready to bring your idea to life?</h2>
    </section>
    <section className="contact_page-form">
    <ContactForm />
    </section>
    </div>
  );
};

export default Contact;
