import React, { useState, useEffect } from "react";
import Particles from "react-tsparticles";
import particleConfig from "./config/particle_config.js";
import { animationOn } from "../../pages/Home";
import { useLocation } from "react-router-dom";

const ParticleMain = () => {
  const [animationOn, CanvasFront] = useState(true);

  const Animation = () => {
    CanvasFront(!animationOn);
  };
  const Particleinit = async (main) => {
    console.log(main);
  };

  const ParticlesLoaded = (container) => {
    console.log(container);
  };
  return (
    <div>
      <Particles
        id={animationOn ? "particle-bg" : "particle-front"}
        options={particleConfig}
      ></Particles>
      <a
        id={animationOn ? "canvasnofun" : "canvasfun"}
        onClick={Animation}
        type="button"
      >
        <span>{animationOn ? "Turn On" : "Turn Off"}</span>
      </a>
    </div>
  );
};

export default ParticleMain;
