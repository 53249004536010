import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";

const ContactForm = () => {
  const [status, setStatus] = useState("Submit");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    axios({
      method: "POST",
      url: "http://localhost:3001/send",
      data: details,
    }).then(() => {
      setStatus("Sent!");
    });
  };
  return (
    <form onSubmit={handleSubmit} className="animate__animated animate__zoomIn">
      <Col>
        <div id="namediv">
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" required />
        </div>
      </Col>
      <Col>
        <div id="emaildiv">
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" required />
        </div>
      </Col>
      <div id="messagediv">
        <label htmlFor="message">How Can I Help?</label>
        <textarea type="message" id="message" required />
      </div>
      <button type="submit" id="submit-btn">
        {status}
      </button>
    </form>
  );
};

export default ContactForm;
