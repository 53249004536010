import React, { useState, useEffect } from "react";
import { ReactComponent as Logo } from "../../assets/Brendan-Blanchard-Logo-Light.svg";
import { ReactComponent as SmallLogo } from "../../assets/BB-Logo-Light.svg";
import { ReactComponent as SmallLogoDark } from "../../assets/BB-Logo-Dark.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./nav.css";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { BsGithub, BsLinkedin } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { SiAboutdotme } from "react-icons/si";
import { VscCode } from "react-icons/vsc";
import { BiMessageDetail } from "react-icons/bi";
import { Link, useLocation, NavLink } from "react-router-dom";

export const Nav = (props) => {
  const { navClosed, navSlide } = props;

  const menuSlide = () => {
    navClosed ? navSlide(false) : navSlide(true);
  };

    // Scroll to anchor with HashRouter

    const { pathname, hash, key } = useLocation();

    useEffect(() => {
      if (hash === "") {
        window.scrollTo(0, 0);
      }
      else {
        setTimeout(() => {
          const id = hash.replace("#", "");
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView();
          }
        }, 0);
      }
    }, [pathname, hash, key]);

  return (
    <ProSidebar collapsed={navClosed}>
      <SidebarHeader>
        <Link to="/">
          <div className="navlogo">{navClosed ? <SmallLogo /> : <Logo />}</div>
        </Link>
        <div className="menuInteract" onClick={menuSlide}>
          {navClosed ? (
            <button id="menuInteractButton">
              <FontAwesomeIcon icon="caret-right" />
            </button>
          ) : (
            <button id="menuInteractButton">
              <FontAwesomeIcon icon="caret-left" />
            </button>
          )}
        </div>
      </SidebarHeader>
      <SidebarContent>
        <Menu>
          <MenuItem icon={<SiAboutdotme size={22} />}>
            <Link to="/#AboutMe">About Me</Link>
          </MenuItem>
          <MenuItem icon={<VscCode size={22} />}>
            <a href="/#/skills">My Skills</a>
          </MenuItem>
          <MenuItem icon={<BiMessageDetail size={22} />}>
            <a href="/#/contact">Contact Me</a>
          </MenuItem>
        </Menu>
      </SidebarContent>
      <SidebarFooter>
        <div className="sidebar-socials">
          <div id="social-github">
            <a
              className="socials social-github"
              href="https://github.com/brendanblanchard"
              target="_blank"
              rel="noreferrer"
            >
              <BsGithub size={22} />
            </a>
          </div>
          <div id="social-linkedin">
            <a
              className="socials social-linkedin"
              href="https://www.linkedin.com/in/brendan-blanchard-98140811b"
              target="_blank"
              rel="noreferrer"
            >
              <BsLinkedin size={22} />
            </a>
          </div>
          <div id="social-email">
            <a
              className="socials social-mail"
              href="mailto:brendanjblanchard@gmail.com"
              rel="noreferrer"
            >
              <MdEmail size={22} />
            </a>
          </div>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export const MobileNav = (props) => {
  const { mnavClosed, mnavSlide } = props;

  const mobileSlide = () => {
    mnavClosed ? mnavSlide(false) : mnavSlide(true);
  };

  return (
    <nav
      className={
        mnavClosed ? "mobile-navigation-closed" : "mobile-navigation-open"
      }
    >
      <button className="hamburger" onClick={mobileSlide}>
        {" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div className="mobile-nav">
        <ul>
        <li>
            <Link to="/" onClick={mobileSlide}>Home</Link>
          </li>
          <li>
            <Link to="/skills" onClick={mobileSlide}>My Skills</Link>
          </li>
          <li>
            <Link to="/contact" onClick={mobileSlide}>Contact Me</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
